import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const initialState = {
  astroData: [],
  status: 'idle', 
  error: null, 
};

export const fetchAstro = createAsyncThunk(
  'astro/fetchAstro',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("vedhvidhi-admin-token");
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/getAstro`, {
        headers: {
          Authorization: token,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch astrologers');
      const data = await response.json();
      return data?.data; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const postAstro = createAsyncThunk(
  'astro/postAstro',
  async (astroPayload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("vedhvidhi-admin-token");
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/addAstro`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(astroPayload),
      });
      if (!response.ok) throw new Error('Failed to create astrologer');
      const data = await response.json();
      console.log(data,"helodat")
      return data; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateAstro = createAsyncThunk(
  'astro/updateAstro',
  async ({ id, astroPayload }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("vedhvidhi-admin-token");
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/updateAstro/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(astroPayload),
      });
      if (!response.ok) throw new Error('Failed to update astrologer');
      const data = await response.json();
      return { id, ...data }; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const deleteAstro = createAsyncThunk(
  'astro/deleteAstro',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("vedhvidhi-admin-token");
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/deleteAstro/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
      });
      if (!response.ok) throw new Error('Failed to delete astrologer');
      return id; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Slice
const astroSlice = createSlice({
  name: 'astro',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch astrologers
      .addCase(fetchAstro.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAstro.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.astroData = action.payload; // Save fetched astrologers to state
      })
      .addCase(fetchAstro.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Create astrologer
      .addCase(postAstro.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postAstro.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.astroData.push(action.payload.data); // Add new astrologer to state
      })
      .addCase(postAstro.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Update astrologer
      .addCase(updateAstro.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAstro.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.astroData.findIndex((astro) => astro._id === action.payload.id);
        if (index !== -1) {
          state.astroData[index] = { ...state.astroData[index], ...action.payload.data };
        }
      })
      .addCase(updateAstro.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Delete astrologer
      .addCase(deleteAstro.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAstro.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.astroData = state.astroData.filter((astro) => astro._id !== action.payload);
      })
      .addCase(deleteAstro.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default astroSlice.reducer;
