import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';

import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../Redux/Reducers/ProductReducer';
import { fetchSubCategory } from '../../Redux/Reducers/SubCategoryReducer';
import { fetchCommission } from '../../Redux/Reducers/CommissionReducer';
import { BsCloudUpload } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import AvailabilitySelect from './AvailabilitySelect';
import { postAstro } from '../../Redux/Reducers/AstroReducer';


function AstrolgerAdd() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const subCategory = useSelector((state) => state?.SubCategoryReducer?.subCategory);
    const products = useSelector((state) => state?.ProductReducer?.products);
    const commission = useSelector((state) => state?.CommissionReducer?.commission);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedExpertise, setSelectedExpertise] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let localData = JSON.parse(localStorage.getItem("vedhvidhi-admin-data"));

    const [aadharFile, setAadharFile] = useState(null);
    const [panCardFile, setPanCardFile] = useState(null);
    const [gstFile, setGstFile] = useState(null);

    const [currentImage, setCurrentImage] = useState(null);
    const [files, setFiles] = useState({ photo: [], video: [], gallery: [], certificate: [] });



  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsOpen(true);
  };


    // const handleChange = (selected) => {
    //     setSelectedOptions(selected || []);
    //   };

    const handleExpertiseChange = (selected) => {
        setSelectedExpertise(selected || []);
    };


    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (fieldName === 'aadhar') {
            setAadharFile(file);
            setValue('aadharFile', file);
        } else if (fieldName === 'panCard') {
            setPanCardFile(file);
            setValue('panCardFile', file);
        } else if (fieldName === 'gst') {
            setGstFile(file);
            setValue('gstFile', file);
        }
    };




    useEffect(() => {
        dispatch(fetchSubCategory());
        dispatch(fetchProducts());
        dispatch(fetchCommission(localData._id));
    }, [dispatch]);

    useEffect(() => {
        if (selectedCategory) {
            setFilteredSubCategories(subCategory?.filter(sc => sc?.categoryId?._id === selectedCategory?.value));
            setFilteredProducts([]);
            setSelectedSubCategory(null);
        } else {
            setFilteredSubCategories([]);
            setFilteredProducts([]);
        }
    }, [selectedCategory, subCategory]);

    useEffect(() => {
        if (selectedSubCategory) {
            setFilteredProducts(products?.filter(p => p?.subCategoryId?._id === selectedSubCategory?.value));
        } else {
            setFilteredProducts([]);
        }
    }, [selectedSubCategory, products]);

    const onSubmit = async (data) => {
        console.log(data,"data")
        try {
            const result = await dispatch(postAstro(data)).unwrap();
            alert('Astrologer created successfully!');
            console.log(result); 
          } catch (error) {
            alert(`Failed to submit: ${error.message}`);
          }
    };

    const options = [
        { value: 'english', label: 'English' },
        { value: 'hindi', label: 'Hindi' },
        { value: 'Tamil', label: 'Tamil' }
    ]
    const appointOption1 = [
        { value: 'onlineConsultation', label: 'Online Consultation' },
        { value: 'physicalRate', label: 'Physical Rate' }
    ]


    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChange = (selected) => {
        setSelectedOptions(selected || []);
    };

    const CustomOption = (props) => {
        const { data, isSelected, innerRef, innerProps } = props;

        return (
            <div className='hoverSelect_clr' ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px 10px', cursor: 'pointer' }}>
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => { }} 
                    style={{ marginRight: '6px' }}
                />
                <label>{data.label}</label>
            </div>
        );
    };

    const [isOpen, setIsOpen] = useState(false); 
    const handleImageClick = (imageSrc) => {
        setCurrentImage(imageSrc);
        setIsOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setIsOpen(false);
        setCurrentImage("");
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={true} duration="10000" />
            <div>
                <div class="row align-items-center">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Personal Information</h3>
                            <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">

                    <div className='row g-4'>
                        <div className="col-sm-6">
                            <label className="form-label">Full Name</label>
                            <input
                                {...register('fullName', 
                                    // { required: 'Full Name is required' }
                                    )
                                }
                                className="form-control"
                                placeholder="Your Name"
                            />
                            {errors.fullName && <p className="text-danger">{errors.fullName.message}</p>}
                        </div>

                        <div className="col-sm-6">
                            <label className="form-label">Email Id</label>
                            <input {...register('email')} className="form-control" placeholder="Enter Your Email" />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">City</label>
                            <input {...register('city')} className="form-control" placeholder="Enter Your City" />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">State</label>
                            <input
                                {...register('state', 
                                    // { required: 'State is required' }
                                )}
                                className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Your State"
                            />
                            {errors.state && <p className="text-danger">{errors.state.message}</p>}
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Pincode</label>
                            <input
                                {...register('pincode', 
                                    // { required: 'Pincode is required' }
                                )}
                                className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                type="number"
                                placeholder="Enter Your Pincode"
                            />
                            {errors.pincode && <p className="text-danger">{errors.pincode.message}</p>}
                        </div>

                        <div className="col-sm-4">
                            <label className="form-label">Aadhar No.</label>
                            <input
                                {...register('aadharNo', 
                                    // { required: 'Aadhar No. is required', minLength: 12, maxLength: 12 }
                                )}
                                className={`form-control ${errors.aadharNo ? 'is-invalid' : ''}`}
                                type="number"
                                placeholder="Enter Your Aadhar No."
                            />
                            {errors.aadharNo && <p className="text-danger">{errors.aadharNo.message}</p>}
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Pan card No.</label>
                            <input
                                {...register('panCardNo', 
                                    // { required: 'Pan card No. is required' }

                                )}
                                className={`form-control ${errors.panCardNo ? 'is-invalid' : ''}`}
                                type="number"
                                placeholder="Enter Your Pan card No."
                            />
                            {errors.panCardNo && <p className="text-danger">{errors.panCardNo.message}</p>}
                        </div>

                        {/* <div className="col-sm-12">
                            <label className="form-label">Upload Aadhar Front & back photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Upload Aadhar photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-sm-12">
                            <label className="form-label">Upload Aadhar Front & back photo</label>
                            <div className="upload-container">
                                <label htmlFor="aadhar-upload" className="upload-label">Upload Aadhar photo</label>
                                <label htmlFor="aadhar-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input
                                    id="aadhar-upload"
                                    type="file"
                                    {...register('aadharFile', 
                                        // { required: 'Aadhar file is required' }
                                    )}
                                    onChange={(e) => handleFileChange(e, 'aadhar')}
                                />
                            </div>
                            {errors.aadharFile && <p className="text-danger">{errors.aadharFile.message}</p>}
                            {aadharFile && (
                                <div className="review_imgCardMain">
                                    <ul>
                                        <li>
                                            <img src={URL.createObjectURL(aadharFile)} alt="Aadhar preview" />
                                            <button onClick={() => setAadharFile(null)}><IoIosClose /></button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* <div className="col-sm-12">
                            <label className="form-label">Upload Pan card photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Upload aadhar photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div> */}

                        <div className="col-sm-12">
                            <label className="form-label">Upload Pan card photo</label>
                            <div className="upload-container">
                                <label htmlFor="pan-upload" className="upload-label">Upload Pan card photo</label>
                                <label htmlFor="pan-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input
                                    id="pan-upload"
                                    type="file"
                                    {...register('panCardFile', 
                                        // { required: 'Pan card file is required' }
                                    )}
                                    onChange={(e) => handleFileChange(e, 'panCard')}
                                />
                            </div>
                            {errors.panCardFile && <p className="text-danger">{errors.panCardFile.message}</p>}
                            {panCardFile && (
                                <div className="review_imgCardMain">
                                    <ul>
                                        <li>
                                            <img src={URL.createObjectURL(panCardFile)} alt="Pan Card preview" />
                                            <button onClick={() => setPanCardFile(null)}><IoIosClose /></button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Address</label>
                            <textarea
                                {...register('address',
                                    //  { required: 'Address is required' }
                                    )}
                                className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                style={{ minHeight: '80px' }}
                                placeholder="Type Your Address"
                            />
                            {errors.address && <p className="text-danger">{errors.address.message}</p>}
                        </div>
                    </div>
                </div>
            </div>

                <div>
                    <div class="row align-items-center mt-4">
                        <div class="border-0 mb-4">
                            <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                                <h3 class="fw-bold mb-0">Professional Information</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row g-4'>
                            <div className="col-sm-6">
                                <label className="form-label">Education Qualification</label>
                                <input
                                    {...register('educationQualification',
                                        //  { required: 'Education Qualification is required' }
                                        )}
                                    className={`form-control ${errors.educationQualification ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Education Qualification"
                                />
                                {errors.educationQualification && <p className="text-danger">{errors.educationQualification.message}</p>}
                            </div>

                            {/* Language */}
                            <div className="col-sm-6">
                                <label className="form-label">Language</label>
                                <Select
                                    {...register('language', 
                                        // { required: 'Please select language' }
                                    )}
                                    options={options}
                                    isMulti
                                    value={selectedOptions}
                                    onChange={handleChange}
                                    closeMenuOnSelect={false}
                                />
                                {errors.language && <p className="text-danger">{errors.language.message}</p>}
                            </div>

                            {/* Professional Expertise */}
                            <div className="col-sm-6">
                                <label className="form-label">Professional Expertise</label>
                                <Select
                                    {...register('expertise', 
                                        // { required: 'Please select expertise' }
                                    )}
                                    options={options}
                                    isMulti
                                    value={selectedExpertise}
                                    onChange={handleExpertiseChange}
                                    closeMenuOnSelect={false}
                                />
                                {errors.expertise && <p className="text-danger">{errors.expertise.message}</p>}
                            </div>

                            {/* Availability Timing */}
                            <div className="col-sm-6">
                                <label className="form-label">Availability Timing</label>
                                <AvailabilitySelect {...register('availability')} />
                            </div>

                            {/* Professional Experience */}
                            <div className="col-sm-6">
                                <label className="form-label">Professional Experience years</label>
                                <input
                                    {...register('experienceYears', 
                                        // { required: 'Experience years is required' }
                                    )}
                                    className={`form-control ${errors.experienceYears ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Professional Experience years"
                                />
                                {errors.experienceYears && <p className="text-danger">{errors.experienceYears.message}</p>}
                            </div>

                            {/* GST No */}
                            <div className="col-sm-6">
                                <label className="form-label">GST No.</label>
                                <input
                                    {...register('gstNo', 
                                        // { required: 'GST No. is required' }
                                    )}
                                    className={`form-control ${errors.gstNo ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter GST No"
                                />
                                {errors.gstNo && <p className="text-danger">{errors.gstNo.message}</p>}
                            </div>

                            {/* Upload Certificate */}
                            <div className="col-sm-12">
                                <label className="form-label">Upload Certificate</label>
                                <div className="upload-container">
                                    <label htmlFor="file-upload" className="upload-label">Upload Certificate</label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'certificate')}
                                        {...register('certificateFile', 
                                            // { required: 'Certificate file is required' }
                                        )}
                                    />
                                </div>
                                {errors.certificateFile && <p className="text-danger">{errors.certificateFile.message}</p>}
                                {aadharFile && (
                                    <div className="review_imgCardMain">
                                        <ul>
                                            <li>
                                                <img src={URL.createObjectURL(aadharFile)} alt="Certificate preview" />
                                                <button onClick={() => setAadharFile(null)}><IoIosClose /></button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>

                            {/* Upload GST Certificate */}
                            <div className="col-sm-12">
                                <label className="form-label">Upload GST Certificate</label>
                                <div className="upload-container">
                                    <label htmlFor="file-upload" className="upload-label">Upload GST Certificate</label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'gst')}
                                        {...register('gstFile', 
                                            // { required: 'GST file is required' }
                                        )}
                                    />
                                </div>
                                {errors.gstFile && <p className="text-danger">{errors.gstFile.message}</p>}
                                {gstFile && (
                                    <div className="review_imgCardMain">
                                        <ul>
                                            <li>
                                                <img src={URL.createObjectURL(gstFile)} alt="GST Certificate preview" />
                                                <button onClick={() => setGstFile(null)}><IoIosClose /></button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Fees/Rate</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className='row g-4'>
                        <div className="col-sm-12">
                            <label className="form-label">Chat</label>
                            <div className="row">
                                <div className="col-sm-6">
                                    <input
                                        {...register('chatPerMinuteRate', {
                                            // required: 'Per Minute Rate is required',
                                            valueAsNumber: true,
                                            min: { value: 1, message: 'Rate must be greater than 0' }
                                        })}
                                        className={`form-control ${errors.chatPerMinuteRate ? 'is-invalid' : ''}`}
                                        type="number"
                                        placeholder='Per Minute Rate'
                                    />
                                    {errors.chatPerMinuteRate && <p className="text-danger">{errors.chatPerMinuteRate.message}</p>}
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        {...register('chatOfferPrice', {
                                            // required: 'Offer Price is required',
                                            valueAsNumber: true,
                                            min: { value: 1, message: 'Offer Price must be greater than 0' }
                                        })}
                                        className={`form-control ${errors.chatOfferPrice ? 'is-invalid' : ''}`}
                                        type="number"
                                        placeholder='Offer Price Per Minute'
                                    />
                                    {errors.chatOfferPrice && <p className="text-danger">{errors.chatOfferPrice.message}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Call Section */}
                        <div className="col-sm-12">
                            <label className="form-label">Call</label>
                            <div className="row">
                                <div className="col-sm-6">
                                    <input
                                        {...register('callPerMinuteRate', {
                                            // required: 'Per Minute Rate is required',
                                            valueAsNumber: true,
                                            min: { value: 1, message: 'Rate must be greater than 0' }
                                        })}
                                        className={`form-control ${errors.callPerMinuteRate ? 'is-invalid' : ''}`}
                                        type="number"
                                        placeholder='Per Minute Rate'
                                    />
                                    {errors.callPerMinuteRate && <p className="text-danger">{errors.callPerMinuteRate.message}</p>}
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        {...register('callOfferPrice', {
                                            // required: 'Offer Price is required',
                                            valueAsNumber: true,
                                            min: { value: 1, message: 'Offer Price must be greater than 0' }
                                        })}
                                        className={`form-control ${errors.callOfferPrice ? 'is-invalid' : ''}`}
                                        type="number"
                                        placeholder='Offer Price Per Minute'
                                    />
                                    {errors.callOfferPrice && <p className="text-danger">{errors.callOfferPrice.message}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Appointment Section */}
                        <div className="col-sm-12">
                            <label className="form-label">Appointment</label>
                            <Select
                                {...register('appointmentType', 
                                    // { required: 'Please select an appointment type' }
                                )}
                                options={appointOption1}
                                className={`react-select ${errors.appointmentType ? 'is-invalid' : ''}`}
                            />
                            {errors.appointmentType && <p className="text-danger">{errors.appointmentType.message}</p>}
                        </div>

                        {/* Video Call Section */}
                        <div className="col-sm-6">
                            <label className="form-label">Video Call Per Minute Rate</label>
                            <input
                                {...register('videoCallRate', {
                                    // required: 'Video Call Rate is required',
                                    valueAsNumber: true,
                                    min: { value: 1, message: 'Rate must be greater than 0' }
                                })}
                                className={`form-control ${errors.videoCallRate ? 'is-invalid' : ''}`}
                                type="number"
                                placeholder='Video Call Per Minute Rate'
                            />
                            {errors.videoCallRate && <p className="text-danger">{errors.videoCallRate.message}</p>}
                        </div>

                        {/* Physical Price Section */}
                        <div className="col-sm-6">
                            <label className="form-label">Physical Price Per Minute</label>
                            <input
                                {...register('physicalPriceRate', {
                                    // required: 'Physical Price Rate is required',
                                    valueAsNumber: true,
                                    min: { value: 1, message: 'Rate must be greater than 0' }
                                })}
                                className={`form-control ${errors.physicalPriceRate ? 'is-invalid' : ''}`}
                                type="number"
                                placeholder='Physical Price Per Minute'
                            />
                            {errors.physicalPriceRate && <p className="text-danger">{errors.physicalPriceRate.message}</p>}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Bank Details</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {/* <form className='row g-4'>
                        <div className="col-sm-4">
                            <label className="form-label">Name of Bank
                        
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Name of Bank'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">IFSC Code</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='IFSC Code'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Account Number</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Account Number'
                            />
                        </div>
                    </form> */}
                            <div className='row g-4'>
                            <div className="col-sm-4">
                        <label className="form-label">Name of Bank</label>
                        <input
                            {...register('bankName',
                                //  { required: 'Name of Bank is required' }
                                )}
                            className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Name of Bank"
                        />
                        {errors.bankName && <p className="text-danger">{errors.bankName.message}</p>}
                    </div>
                            </div>
                   

                    {/* IFSC Code */}
                    <div className="col-sm-4">
                        <label className="form-label">IFSC Code</label>
                        <input
                            {...register('ifscCode', {
                                // required: 'IFSC Code is required',
                                pattern: {
                                    value: /^[A-Za-z]{4}\d{7}$/,
                                    message: 'Please enter a valid IFSC code',
                                },
                            })}
                            className={`form-control ${errors.ifscCode ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="IFSC Code"
                        />
                        {errors.ifscCode && <p className="text-danger">{errors.ifscCode.message}</p>}
                    </div>

                    {/* Account Number */}
                    <div className="col-sm-4">
                        <label className="form-label">Account Number</label>
                        <input
                            {...register('accountNumber', {
                                // required: 'Account Number is required',
                                pattern: {
                                    value: /^[0-9]{10,18}$/,
                                    message: 'Please enter a valid account number',
                                },
                            })}
                            className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Account Number"
                        />
                        {errors.accountNumber && <p className="text-danger">{errors.accountNumber.message}</p>}
                    </div>
                </div>
            </div>
            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Billing Details</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {/* <form className='row g-4'>
                        <div className="col-sm-12">
                            <label className="form-label">PAN Card</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='PAN Card'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Aadhar Card</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Aadhar Card'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">GST Number (Optional)</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='GST Number '
                            />
                        </div>
                    </form> */}
                    <div className='row g-4'>

                        <div className="col-sm-12">
                            <label className="form-label">PAN Card</label>
                            <input
                                {...register('panCard', {
                                    // required: 'PAN Card is required',
                                    pattern: {
                                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                        message: 'Please enter a valid PAN card number',
                                    }
                                })}
                                className={`form-control ${errors.panCard ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder='PAN Card'
                            />
                            {errors.panCard && <p className="text-danger">{errors.panCard.message}</p>}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label">Aadhar Card</label>
                        <input
                            {...register('aadharCard', {
                                // required: 'Aadhar Card is required',
                                pattern: {
                                    value: /^[2-9]{1}[0-9]{11}$/,
                                    message: 'Please enter a valid Aadhar card number',
                                }
                            })}
                            className={`form-control ${errors.aadharCard ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder='Aadhar Card'
                        />
                        {errors.aadharCard && <p className="text-danger">{errors.aadharCard.message}</p>}
                    </div>


                    <div className="col-sm-6">
                        <label className="form-label">GST Number (Optional)</label>
                        <input
                            {...register('gstNumber', {
                                pattern: {
                                    value: /^[0-9]{15}$/,
                                    message: 'Please enter a valid GST number (if provided)',
                                }
                            })}
                            className={`form-control ${errors.gstNumber ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder='GST Number'
                        />
                        {errors.gstNumber && <p className="text-danger">{errors.gstNumber.message}</p>}
                    </div>
                </div>
            </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">About Me</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {/* <form className='row g-4'>
                        <div className="col-sm-12">
                            <label className="form-label">Profile Bio</label>
                            <textarea style={{ minHeight: '80px' }} className='form-control' placeholder='Profiel Bio...'></textarea>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">My Video</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">My Video</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <video controls preload="none">
                                            <source src="/assets/video/Video.mp4" type="video/mp4" />
                                          
                                            Your browser does not support the video tag.
                                        </video>
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <video controls preload="none">
                                            <source src="/assets/video/Video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Gallery Photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Gallery Photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Certificate Gallery</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Certificate Gallery</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}
                            </button>
                            <Link to="/coupons-list" className="btn btn-secondary text-uppercase px-3 mx-2">Cancel</Link>
                        </div>

                        {/* Modal */}
                    {isOpen && (
                        <>
                            <div className={`modalss ${isOpen ? "show" : ""}`}>
                                <div className={`modal-contentss ${isOpen ? "show" : ""}`}>
                                    <button className="close-btnss" onClick={closeModal}>
                                        ✖
                                    </button>
                                    <img src={currentImage} alt="Pop-up" />
                                </div>
                            </div>
                            <div className={`modal-overlayss ${isOpen ? "show" : ""}`} onClick={closeModal}></div>
                        </>
                    )}
                    {/* </form> */}

                    <div className='row g-4'>

                        <div className="col-sm-12">
                            <label className="form-label">Profile Bio</label>
                            <textarea
                                style={{ minHeight: '80px' }}
                                className='form-control'
                                placeholder='Profile Bio...'
                                {...register('profileBio')}
                            ></textarea>
                        </div>

                        <div className="col-sm-12">
                            <label className="form-label">Photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload-photo" className="upload-label">Photo</label>
                                <label htmlFor="file-upload-photo">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input
                                    id="file-upload-photo"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'photo')}
                                    multiple
                                    {...register('photo')}
                                />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    {files.photo.map((file, index) => (
                                        <li key={index}>
                                            <img src={URL.createObjectURL(file)} alt="preview" onClick={() => openModal(URL.createObjectURL(file))} />
                                            <button type="button" onClick={() => setFiles(prev => ({ ...prev, photo: prev.photo.filter((_, i) => i !== index) }))}>
                                                <IoIosClose />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label className="form-label">My Video</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload-video" className="upload-label">My Video</label>
                                <label htmlFor="file-upload-video">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input
                                    id="file-upload-video"
                                    type="file"
                                    accept="video/*"
                                    onChange={(e) => handleFileChange(e, 'video')}
                                    {...register('video')}
                                />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    {files.video.map((file, index) => (
                                        <li key={index}>
                                            <video controls preload="none">
                                                <source src={URL.createObjectURL(file)} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                            <button type="button" onClick={() => setFiles(prev => ({ ...prev, video: prev.video.filter((_, i) => i !== index) }))}>
                                                <IoIosClose />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label className="form-label">Gallery Photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload-gallery" className="upload-label">Gallery Photo</label>
                                <label htmlFor="file-upload-gallery">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input
                                    id="file-upload-gallery"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'gallery')}
                                    multiple
                                    {...register('gallery')}
                                />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    {files.gallery.map((file, index) => (
                                        <li key={index}>
                                            <img src={URL.createObjectURL(file)} alt="preview" onClick={() => openModal(URL.createObjectURL(file))} />
                                            <button type="button" onClick={() => setFiles(prev => ({ ...prev, gallery: prev.gallery.filter((_, i) => i !== index) }))}>
                                                <IoIosClose />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label className="form-label">Certificate Gallery</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload-certificate" className="upload-label">Certificate Gallery</label>
                                <label htmlFor="file-upload-certificate">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input
                                    id="file-upload-certificate"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'certificate')}
                                    multiple
                                    {...register('certificate')}
                                />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    {files.certificate.map((file, index) => (
                                        <li key={index}>
                                            <img src={URL.createObjectURL(file)} alt="preview" onClick={() => openModal(URL.createObjectURL(file))} />
                                            <button type="button" onClick={() => setFiles(prev => ({ ...prev, certificate: prev.certificate.filter((_, i) => i !== index) }))}>
                                                <IoIosClose />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                        <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                                {isLoading ? 'Saving...' : 'Save'}
                            </button>
                            <Link to="/coupons-list" className="btn btn-secondary text-uppercase px-3 mx-2">Cancel</Link>
                        </div>

                        {/* Modal */}
                        {isOpen && (
                            <>
                                <div className={`modalss ${isOpen ? "show" : ""}`}>
                                    <div className={`modal-contentss ${isOpen ? "show" : ""}`}>
                                        <button className="close-btnss" onClick={closeModal}>✖</button>
                                        <img src={currentImage} alt="Pop-up" />
                                    </div>
                                </div>
                                <div className={`modal-overlayss ${isOpen ? "show" : ""}`} onClick={closeModal}></div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AstrolgerAdd;