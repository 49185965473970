import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import { fetchUsers } from '../../Redux/Reducers/UserManagementReducer';
import { useDispatch, useSelector } from 'react-redux';

function BasicInformation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

     useEffect(() => {
          dispatch(fetchUsers());
      }, [dispatch]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (id) {
        try {
        //   const token = localStorage.getItem("token");
          const response = await axios.get(`${process.env.REACT_APP_BASEURL}/admin/user/${id}`, {
            // headers: { Authorization: `Bearer ${token}` },
          });
  
          const userData = response?.data;
          console.log(userData, "userData");
  
          if (userData) {
            setValue("full_name", userData.fullName);
            setValue("email", userData.email);
            setValue("mobile_number", userData.mobileNumber);
            setValue("state", userData.state);
            setValue("city", userData.city);
            setValue("pincode", userData.pincode);
            setValue("landmark", userData.landmark);
            // setValue("dob", userData.dob);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
  
    fetchUserData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    const payload = {
      fullName: data.full_name,
      email: data.email.toLowerCase(),
      profileImg: profilePicture ? URL.createObjectURL(profilePicture) : "",
      mobileNumber: data.mobile_number,
      street: data.street,
      landmark: data.landmark || "",
      houseNo: data.house_no,
      pincode: data.pincode,
      state: data.state,
      city: data.city,
      dob: data.dob,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/user`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User added successfully!");
      navigate("/users-list");
    } catch (error) {
      setLoading(false);

      if (error?.response?.data?.errors) {
        error.response.data.errors.forEach((err) => {
          setError(err.field, {
            type: "manual",
            message: err.message,
          });
        });
      } else {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h3 className="fw-bold mb-0">Add User</h3>
            <button type="submit" className="btn btn-primary text-uppercase px-3" onClick={handleSubmit(onSubmit)}>
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-sm-12">
            <div className="file-upload">
              <label htmlFor="file-input">
                <img
                  src={
                    profilePicture
                      ? URL.createObjectURL(profilePicture)
                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                  alt="Profile Icon"
                  className="icon"
                />
                <div className="edit-icon">
                  <FaPen />
                </div>
              </label>
              <input type="file" id="file-input" onChange={handleFileChange} />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Full name"
                {...register("full_name", {
                  required: "This field is required",
                })}
              />
              {errors.full_name && <span className="text-danger">{errors.full_name.message}</span>}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "This field is required",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    message: "Invalid Email Address",
                  },
                })}
              />
              {errors.email && <span className="text-danger">{errors.email.message}</span>}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                maxLength={10}
                placeholder="Mobile Number"
                {...register("mobile_number", {
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Mobile number must be a 10-digit numeric value.",
                  },
                })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                }}
              />
              {errors.mobile_number && <span className="text-danger">{errors.mobile_number.message}</span>}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">
                Pincode <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                maxLength={6}
                placeholder="Pincode"
                {...register("pincode", {
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]{6}$/,
                    message: "Pincode must be a 6-digit numeric value.",
                  },
                })}
              />
              {errors.pincode && <span className="text-danger">{errors.pincode.message}</span>}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">
                Landmark <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Landmark"
                {...register("landmark", {
                  required: "This field is required",
                })}
              />
              {errors.landmark && <span className="text-danger">{errors.landmark.message}</span>}
            </div>
          </div>

          <div className="col-12 mt-4">
            {loading ? (
              <button type="submit" disabled className="btn btn-primary text-uppercase px-5">
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary text-uppercase px-5">
                Save
              </button>
            )}
            <Link to="/users-list" className="btn btn-primary text-uppercase px-5 mx-2">
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default BasicInformation;
