// import React, { useState, useEffect } from 'react';
// import PageHeader1 from '../../components/common/PageHeader1';
// import Course from '../../components/Course/CourseAddEdit';
// import TrackStepper from '../../components/Stepper/TrackStepper';
// import { useLocation, useParams } from 'react-router-dom';
// import axios from 'axios';

// function CourseAdd() {
//     const [steps, setSteps] = useState(0);
//     const [currentStep, setCurrentStep] = useState(0);
//     const [isDraft, setIsDraft] = useState(false); 
//     const location = useLocation();
//     const { courseId } = useParams();  


//     useEffect(() => {
    
//         if (location.state?.step) {
         
//             setCurrentStep(location.state.step);
//             setSteps(location.state.step); 
//         } else if (courseId) {
            
//             axios.get(`/api/courses/${courseId}`).then(response => {
//                 const course = response.data;
//                 setIsDraft(course.isDraft);
//                 setCurrentStep(course.step);
//                 setSteps(course.step); 
//             }).catch(error => {
//                 console.error('Error fetching course data:', error);
//             });
//         } else {
          
//             setCurrentStep(1);
//             setSteps(1);
//         }
//     }, [location.state, courseId]);

  
//     const handleSteps = (newStep, type) => {
//         if (type === 'step') {
           
//             if (newStep <= currentStep) {
//                 setSteps(newStep);
//             }
//         } else {

//             setSteps(newStep);
//             setCurrentStep(newStep);
//         }
//     };

//     return (
//         <div className="body d-flex py-lg-3 py-md-2">
//             <div className="container-xxl">
//                 <PageHeader1 
//                     pagetitle={location.pathname?.split('/')?.[1] === 'course-edit' ? 'Edit Course' : 'Add Course'} 
//                 />
//                 <div className="row clearfix g-3">
//                     <div className="col-lg-12">
//                         <TrackStepper steps={steps} handleSteps={handleSteps} />
//                     </div>
//                     <div className="col-lg-12">
//                         <Course
//                             handleSteps={handleSteps}
//                             steps={steps}
//                             setCurrentStep={setCurrentStep}
//                             isDraft={isDraft} 
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default CourseAdd;




import React, { useState } from 'react';
import PageHeader1 from '../../components/common/PageHeader1';
import Course from '../../components/Course/CourseAddEdit';
import TrackStepper from '../../components/Stepper/TrackStepper';
import { useLocation } from 'react-router';

function CourseAdd() {
    const [steps, setSteps] = useState(0);
    const [currentStep, setCurrentStep] = useState(0)
    const location = useLocation();

    const handleSteps = (steps, type) => {
        console.log(currentStep, steps ,"jjdjdjdjdj")
        if(type === 'step'){
             if(steps <= currentStep){
                setSteps(steps)
             }
        }else{
            setSteps(steps)
            setCurrentStep(steps)
        }
    }

    return (
        <div className="body d-flex py-lg-3 py-md-2">
            <div className="container-xxl">
                <PageHeader1 pagetitle={location.pathname?.split('/')?.[1] === 'course-edit' ? 'Edit Course' : 'Add Course'} />
                <div className="row clearfix g-3">
                    <div className="col-lg-12">
                        <TrackStepper steps={steps} handleSteps={handleSteps}/>
                    </div>
                    <div className="col-lg-12">
                        <Course
                            handleSteps={handleSteps}
                            steps={steps}
                            setCurrentStep={setCurrentStep}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CourseAdd;
