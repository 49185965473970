import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PageHeader1 from '../../components/common/PageHeader1';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
} from '@mui/material';
import userImg from "../../assets/images/user.svg";
import { fetchUsers } from '../../Redux/Reducers/UserManagementReducer';
import { useDispatch, useSelector } from 'react-redux';

function UsersList() {
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const usersData = useSelector((state) => state?.UserManagementReducer?.users);
    console.log(usersData,"kkskksks")
    const loading = useSelector((state) => state?.UsersReducer?.loading);

    const deleteUser = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASEURL}/admin/user/${id}`);
            if (response.status === 200) {
                dispatch(fetchUsers());
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleClickOpen = (userId) => {
        setSelectedUserId(userId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        if (selectedUserId) {
            deleteUser(selectedUserId);
        }
        setOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 80 },
        {
            field: 'profileImg',
            headerName: 'Image',
            width: 150,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="NA"
                    style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }}
                />
            ),
        },
        { field: 'fullName', headerName: 'Name', width: 200 },
        { field: 'email', headerName: 'Email', type: 'email', width: 250 },
        { field: 'mobileNumber', headerName: 'Mobile', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ row }) => (
                <div>
                    <IconButton component={Link} to={`${process.env.PUBLIC_URL}/users-edit/${row._id}`}>
                        <i className="icofont-edit text-primary"></i>
                    </IconButton>
                    <IconButton component="button" onClick={() => handleClickOpen(row._id)}>
                        <i className="icofont-ui-delete text-danger"></i>
                    </IconButton>
                </div>
            ),
        },
    ];

 

    const rows = Array.isArray(usersData?.data) ? usersData?.data?.map((user,index) => ({
        id: index + 1,
        profileImg: user?.profileImg || userImg,
        fullName: user?.fullName,
        email: user?.email,
        mobileNumber: user?.mobileNumber,
        _id: user?._id,
       
      })) : [];

    return (
        <div className="body d-flex py-1">
            <div className="container-xxl">
                <PageHeader1 righttitle="Add Users" link="/users-add" routebutton={true} />
                <div className="row g-0 mb-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                disableColumnFilter
                                                disableDensitySelector
                                                slots={{ toolbar: GridToolbar }}
                                                filterModel={{
                                                    items: [],
                                                    quickFilterExcludeHiddenColumns: true,
                                                }}
                                                slotProps={{
                                                    toolbar: { showQuickFilter: true },
                                                }}
                                                columnVisibilityModel={{
                                                    id: true,
                                                    profileImg: true,
                                                    fullName: true,
                                                    email: true,
                                                    mobileNumber: true,
                                                    actions: true,
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UsersList;
