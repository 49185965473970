import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PageHeader1 from '../../components/common/PageHeader1';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Toaster, toast } from "react-hot-toast";

import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFAQs } from '../../Redux/Reducers/FAQReducer';

function FAQList() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const faq = useSelector((state) => state?.FAQReducer?.faq);

    useEffect(() => {
        dispatch(fetchFAQs());
    }, [dispatch]);

    const handleClickOpen = (userId) => {
        setSelectedUserId(userId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        if (selectedUserId) {
            try {
                const token = localStorage.getItem("vedhvidhi-admin-token"); 
                await axios.delete(`${process.env.REACT_APP_BASEURL}/admin/faq/${selectedUserId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
                });
                toast.success("FAQ deleted successfully!"); 
                dispatch(fetchFAQs()); 
                setOpen(false); 
            } catch (error) {
                console.error("Error deleting FAQ:", error);
                toast.error("Failed to delete FAQ!"); 
            }
        }
    };
    

    const columns = [
        { field: 'serialNo', headerName: 'S.No', width: 100 },
        { field: 'courseName', headerName: 'Course Name', width: 200 },
        { field: 'question', headerName: 'Question', width: 300 },
        { field: 'answer', headerName: 'Answer', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: ({ row }) => (
                <div>
                    <IconButton component={Link} to={`/faq-edit/${row?.parentId}`}>
                        <i className="icofont-edit text-danger"></i>
                    </IconButton>
                    <IconButton component="button" onClick={() => handleClickOpen(row.parentId)}>
                        <i className="icofont-ui-delete text-danger"></i>
                    </IconButton>
                </div>
            ),
        },
    ];
    const rows = Array.isArray(faq?.data)
        ? faq?.data?.flatMap((item, index) =>
            item?.faqEntries?.map((entry, entryIndex) => ({
                serialNo: index + 1,
                courseName: item?.courseId?.title || 'N/A',
                question: entry?.question,
                answer: entry?.answer,
                id: entry?._id, 
                parentId: item?._id,
            }))
        )
        : [];

    return (
        <>
        <Toaster position="top-right" reverseOrder={true} />
        <div className="body d-flex py-1">
            <div className="container-xxl">
                <PageHeader1 pagetitle="FAQ List" righttitle="Add FAQ" link="/faq-add" routebutton={true} />
                <div className="row g-0 mb-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <Box sx={{ width: 1 }}>
                                    <Box sx={{ height: 500 }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[10, 20, 50]}
                                            slots={{ toolbar: GridToolbar }}
                                            disableSelectionOnClick
                                        />
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this FAQ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        </>
       
    );
}

export default FAQList;
