import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
    faq: [],
    status: 'idle', 
    error: null, 
};

export const fetchFAQs = createAsyncThunk('faq/fetchFAQs', async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/faq`);
        if (!response.ok) {
            throw new Error('Failed to fetch FAQs');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
});


export const deleteFAQ = createAsyncThunk('faq/deleteFAQ', async (id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/faq/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Failed to delete FAQ');
        }
        return id; 
    } catch (error) {
        throw error;
    }
});


export const createFAQ = createAsyncThunk('faq/createFAQ', async (faqData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/faq`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(faqData),
        });
        if (!response.ok) {
            throw new Error('Failed to create FAQ');
        }
        const data = await response.json();
        return data; 
    } catch (error) {
        throw error;
    }
});


const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          
            .addCase(fetchFAQs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFAQs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.faq = action.payload; 
            })
            .addCase(fetchFAQs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

           
            .addCase(deleteFAQ.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteFAQ.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.faq = state.faq.filter((faq) => faq._id !== action.payload); 
            })
            .addCase(deleteFAQ.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            
            .addCase(createFAQ.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createFAQ.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.faq.push(action.payload); 
            })
            .addCase(createFAQ.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default faqSlice.reducer;
