import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import { useNavigate, useParams } from "react-router";

function AddReviewCourse() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      comments: [{ comment: "", rating: 1 }],
      descriptions: [{ description: "" }],
    },
  });

  const { fields: commentFields, append: appendComment, remove: removeComment } = useFieldArray({
    control,
    name: "comments",
  });

  const { fields: descriptionFields, append: appendDescription, remove: removeDescription } = useFieldArray({
    control,
    name: "descriptions",
  });

  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  console.log(id, "idddddddddddddddd");

  useEffect(() => {
    if (!courses.length) {
      const fetchCourses = async () => {
        try {
          const token = localStorage.getItem("vedhvidhi-admin-token");
          const response = await axios.get(`${process.env.REACT_APP_BASEURL}/admin/course`, {
            headers: { Authorization: token },
          });
          setCourses(response?.data?.data || []);
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchCourses();
    }

    if (id) {
      const fetchReviewData = async () => {
        try {
          const token = localStorage.getItem("vedhvidhi-admin-token");
          const response = await axios.get(`${process.env.REACT_APP_BASEURL}/admin/review/${id}`, {
            headers: { Authorization: token },
          });
          const reviewData = response?.data?.data;
          console.log(reviewData?.courseId?.title, "kkdkdkdkkd");
          if (reviewData) {
            setValue("courseId", reviewData?.courseId?._id);
            setValue(
              "comments",
              reviewData.reviews.map((rev) => ({
                comment: rev.comment,
                rating: rev.rating || 1, // Default rating to 1 if missing
              }))
            );
            setValue("descriptions", reviewData.reviews.map((rev) => ({ description: rev.description })));

            const selected = courses.find((course) => course._id === reviewData?.courseId?._id);
            console.log(selected, "selectedhhshhjshjs");

            if (selected) {
              setSelectedCourse(selected);
            }
          }
        } catch (error) {
          console.error("Error fetching review data:", error);
        }
      };
      fetchReviewData();
    }
  }, [id, courses, setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const reviews = data.comments.map((comment, index) => ({
        comment: comment.comment,
        rating: comment.rating, // Include rating
        description: data.descriptions[index]?.description || "",
      }));

      const formattedData = {
        courseId: data.courseId,
        reviews,
      };

      const response = id
        ? await axios.patch(`${process.env.REACT_APP_BASEURL}/admin/review/${id}`, formattedData, {
            headers: { "Content-Type": "application/json" },
          })
        : await axios.post(`${process.env.REACT_APP_BASEURL}/admin/review`, formattedData, {
            headers: { "Content-Type": "application/json" },
          });

      if (response.status === 200 || response.status === 201) {
        toast.success("Review saved successfully!");
        navigate("/course-specific");
      } else {
        toast.error("Failed to save review!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} duration="10000" />
      <div className="card-body">
        <h2>{id ? "Edit Review Course" : "Add Review Course"}</h2>
        <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
          {/* Select Course */}
          <div className="col-md-6">
            <label className="form-label">Select Course</label>
            <span style={{ color: "red" }}>*</span>
            <Controller
              name="courseId"
              control={control}
              rules={{ required: "Course is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={courses?.map((course) => ({
                    value: course._id,
                    label: course.title,
                  }))}
                  placeholder="Select Course"
                  value={
                    selectedCourse
                      ? { value: selectedCourse._id, label: selectedCourse.title }
                      : null
                  }
                  onChange={(selectedOption) => {
                    const selected = courses.find((course) => course._id === selectedOption.value);
                    setSelectedCourse(selected);
                    field.onChange(selectedOption.value);
                  }}
                />
              )}
            />
            {errors.courseId && <span className="text-danger">{errors.courseId.message}</span>}
          </div>

          {/* Course Details */}
          {selectedCourse && (
            <div className="col-md-12">
              <h5>Review Course Details</h5>
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={selectedCourse?.courseImg || "https://via.placeholder.com/100"}
                    alt={selectedCourse?.title || "Placeholder"}
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="mb-3">
                    <label className="form-label">
                      <strong>Category</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedCourse?.categoryId?.categoryName || ""}
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <strong>Type</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedCourse?.courseType || ""}
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <strong>Tutor</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedCourse?.tutorsName || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-md-12">
            {commentFields?.map((item, index) => (
              <div key={item.id} className="mb-4">
                {/* Comment Field */}
                <label className="form-label">Comment</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter your comment"
                  {...register(`comments[${index}].comment`, { required: "Comment is required" })}
                />
                {errors.comments?.[index]?.comment && (
                  <span className="text-danger">{errors.comments[index]?.comment?.message}</span>
                )}

                {/* Rating Field */}
                <label className="form-label mt-3">Rating</label>
                <select
                  className="form-control"
                  {...register(`comments[${index}].rating`, { required: "Rating is required" })}
                  defaultValue={1} // Default to 1
                >
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <option key={rating} value={rating}>
                      {rating}
                    </option>
                  ))}
                </select>
                {errors.comments?.[index]?.rating && (
                  <span className="text-danger">{errors.comments[index]?.rating?.message}</span>
                )}

                {/* Description Field */}
                <label className="form-label mt-3">Description</label>
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="Enter detailed description"
                  {...register(`descriptions[${index}].description`, { required: "Description is required" })}
                />
                {errors.descriptions?.[index]?.description && (
                  <span className="text-danger">{errors.descriptions[index]?.description?.message}</span>
                )}

                {/* Remove Button */}
                {commentFields.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={() => {
                      removeComment(index);
                      removeDescription(index);
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            ))}

            {/* Common Add Button */}
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => {
                appendComment({ comment: "", rating: 1 }); // Default new rating to 1
                appendDescription({ description: "" });
              }}
            >
              +
            </button>
          </div>

          {/* Submit Button */}
          <div className="col-12 mt-4">
            <button
              type="submit"
              className="btn btn-primary text-uppercase px-3"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddReviewCourse;
