import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { postLanguage } from '../../Redux/Reducers/LanguageReducer'; 
import axios from 'axios';

function LanguageEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();  
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  // const fetchLanguage = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BASEURL}/admin/language/${id}`);
  //     setValue('languageName', response.data.languageName); 
  //   } catch (error) {
  //     toast.error('Failed to fetch language details');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchLanguage();  
  // }, [id]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await dispatch(postLanguage(data.languageName));  
      toast.success('Language updated successfully!');
      navigate('/language-list');  
    } catch (error) {
      toast.error('Failed to update language');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} duration={10000} />
      <div className="card-body">
        <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-sm-6">
            <label className="form-label">Language Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter language name (e.g. Hindi)"
              {...register('languageName', { required: 'Language Name is required' })}
            />
            {errors.languageName && <span className="text-danger">{errors.languageName.message}</span>}
          </div>

          {/* Submit Button */}
          <div className="col-12 mt-4">
            <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
            <button
              type="button"
              className="btn btn-secondary text-uppercase px-3 mx-2"
              onClick={() => navigate('/language-list')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default LanguageEdit;
