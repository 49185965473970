import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  languages: [], 
  status: 'idle', 
  error: null,    
};

export const fetchLanguages = createAsyncThunk('languages/fetchLanguages', async () => {
  const token = localStorage.getItem("vedhvidhi-admin-token");
  


  const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/language`, {
    method: 'GET',
    headers: {
      'Authorization': ` ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch languages');
  }

  const data = await response.json();
  return data;  
});

export const postLanguage = createAsyncThunk('languages/postLanguage', async (languageName) => {
  const token = localStorage.getItem("vedhvidhi-admin-token");

  if (!token) {
    throw new Error('Authorization token not found');
  }

  const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/language`, {
    method: 'POST',
    headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ languageName }),  
  });

  if (!response.ok) {
    throw new Error('Failed to add language');
  }

  const data = await response.json();
  return data;
});

const languagesSlice = createSlice({
  name: 'languages',  
  initialState,
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.pending, (state) => {
        state.status = 'loading';  
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.status = 'succeeded';  
        state.languages = action.payload;  
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.status = 'failed'; 
        state.error = action.error.message;  
      });

    builder
      .addCase(postLanguage.pending, (state) => {
        state.status = 'loading'; 
      })
      .addCase(postLanguage.fulfilled, (state, action) => {
        state.status = 'succeeded';  
        state.languages.push(action.payload);  
      })
      .addCase(postLanguage.rejected, (state, action) => {
        state.status = 'failed';  
        state.error = action.error.message;  
      });
  },
});

export default languagesSlice.reducer;
