import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
  users: [], 
  status: 'idle', 
  error: null, 
};


export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const token = localStorage.getItem('vedhvidhi-admin-token');

  const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/user`, {
    method: 'GET',
    headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }

  const data = await response.json();
  return data;
});

export const addUser = createAsyncThunk('users/addUser', async (userData) => {
  const token = localStorage.getItem('vedhvidhi-admin-token');

  const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/user`, {
    method: 'POST',
    headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData), 
  });

  if (!response.ok) {
    throw new Error('Failed to add user');
  }

  const data = await response.json();
  return data;
});


const usersSlice = createSlice({
  name: 'users', 
  initialState,
  reducers: {}, 
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading'; 
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload; 
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed'; 
        state.error = action.error.message; 
      });

    builder
     
      .addCase(addUser.pending, (state) => {
        state.status = 'loading'; 
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.status = 'succeeded'; 
        state.users.push(action.payload); 
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = 'failed'; 
        state.error = action.error.message; 
      });
  },
});


export default usersSlice.reducer;
