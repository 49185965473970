import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress
} from '@mui/material';
import PageHeader1 from '../../../components/common/PageHeader1';

// import { fetchCourse } from '../../Redux/Reducers/CourseReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchreview } from '../../../Redux/Reducers/ReviewCourseReducer';

function CourseListReview() {
    let dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    const course = useSelector((state) => state?.ReviewCourseReducer?.review);
    console.log(course,"course")
    useEffect(() => {
        dispatch(fetchreview())
    }, [dispatch]);

    function deleteCourse(id) {
        axios.delete(`${process.env.REACT_APP_BASEURL}/admin/review/${id}`)
            .then(response => {
                dispatch(fetchreview())
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleClickOpen = (userId) => {
        setSelectedUserId(userId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        if (selectedUserId) {
            const userToDelete = course.data?.find(row => row._id === selectedUserId);
            if (userToDelete && userToDelete._id) {
                deleteCourse(userToDelete._id);
            }
        }
        setOpen(false);
    };

    const columns = [
        { field: 'SoNo', headerName: 'SoNo', width: 150 },
        { field: 'categoryName', headerName: 'Category Name', width: 250 },
        { field: 'title', headerName: 'Course Name', width: 250 },
        { field: 'reviews', headerName: 'Number of reviews', width: 250 },
        { field: 'rating', headerName: 'Rating', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ row }) => {
              console.log(row, "gsggsgsggsgsg"); 
              return (
                <div>
                  <IconButton component={Link} to={process.env.PUBLIC_URL + `/editReviewCourse/${row?.id}`}>
                    <i className="icofont-edit text-danger"></i>
                  </IconButton>
                  <IconButton component="button" onClick={() => handleClickOpen(row?.id)}>
                    <i className="icofont-ui-delete text-danger"></i>
                  </IconButton>
                </div>
              );
            },
          }
          
    ];

    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [''],
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    const rows = Array.isArray(course.data)
    ? course.data?.map((item, index) => {
        console.log(item, "uuuuu");
  
       
        const reviews = Array.isArray(item?.reviews) ? item.reviews : [];
        const averageRating =
          reviews.length > 0
            ? (reviews.reduce((sum, review) => sum + (review.rating || 0), 0) / reviews.length).toFixed(1)
            : "-";
  
            console.log(averageRating,"averageRating")
        // Concatenate all ratings as a string
        const allRatings = reviews.map((review) => review.rating).join(", ");

        console.log(allRatings,"allRatings")
  
        return {
          SoNo: index + 1,
          id: item?._id,
          title: item?.courseId?.title ?? "-",
          categoryName: item?.courseId?.categoryId?.categoryName,
          reviews: reviews.length,
          rating: averageRating, 
      
        };
      })
    : [];
  

 
    
    return (
        <div className="body d-flex py-1">
            <div className="container-xxl">
                <PageHeader1 righttitle='Add Review' link='/AddReviewCourse' routebutton={true} />
                <div className="row g-0 mb-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Box sx={{ width: 1 }}>
                                                <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {loading ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <DataGrid
                                                            columns={columns}
                                                            rows={rows}
                                                            disableColumnFilter
                                                            disableDensitySelector
                                                            slots={{ toolbar: GridToolbar }}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                            slotProps={{ toolbar: { showQuickFilter: true } }}
                                                            columnVisibilityModel={columnVisibilityModel}
                                                            onColumnVisibilityModelChange={(newModel) =>
                                                                setColumnVisibilityModel(newModel)
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        handleDelete();
                    }} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CourseListReview ;