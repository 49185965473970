"use client";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguages } from "../../Redux/Reducers/LanguageReducer";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import PageHeader1 from "../../components/common/PageHeader1";

function LanguageList() {
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state?.LanguageReducer?.languages
  );
  console.log(languageData, "languages");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    dispatch(fetchLanguages());
  }, [dispatch]);

 
  const handleClickOpen = (userId) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  
  const handleClose = () => {
    setOpen(false);
  };

 
  const columns = [
    { field: "name", headerName: "Language Name", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: ({ row }) => (
        <div>
          <IconButton component={Link} to={`/language-edit/${row._id}`}>
            <i className="icofont-edit text-danger"></i>
          </IconButton>
          <IconButton component="button" onClick={() => handleClickOpen(row._id)}>
            <i className="icofont-ui-delete text-danger"></i>
          </IconButton>
        </div>
      ),
    },
  ];

  console.log("Language Data:", languageData?.data);

  
  const rows = languageData?.data?.map((language) => {
    console.log("Mapping Language:", language);
    return {
      id: language._id, 
      name: language.languageName, 
      _id: language._id,
    };
  }) || []; 

  console.log("Mapped Rows:", rows);

  return (
    <div className="body d-flex py-1">
      <div className="container-xxl">
        <PageHeader1 righttitle="Add Language" link="/language-add" routebutton={true} />
        <div className="row g-0 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                  <div className="row">
                    <div className="col-sm-12">
                      <Box sx={{ width: 1 }}>
                        <Box sx={{ height: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <DataGrid
                              columns={columns}
                              rows={rows} // Use rows with a default empty array
                              disableColumnFilter
                              disableDensitySelector
                              slots={{ toolbar: GridToolbar }}
                            />
                          )}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete confirmation dialog */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this language?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LanguageList;
