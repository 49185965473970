import { combineReducers } from "redux";
import Mainreducer from "./Mainreducer";
import ProductReducer from "./ProductReducer";
import CategoryReducer from "./CategoryReducer";
import SubCategoryReducer from "./SubCategoryReducer";
import AttributeReducer from "./AttributeReducer";
import TagReducer from "./TagReducer";
import CouponReducer from "./CouponReducer";
import BrandReducer from "./BrandReducer";
import InterestsReducer from "./InterestsReducer";
import ProfileReducer from "./ProfileReducer";
import ServiceReducer from "./ServiceReducer";
import CommissionReducer from "./CommissionReducer";
import FAQReducer from "./FAQReducer";
import UsersReducer from "./UsersReducer";
import CsvInterestReducer from "./CsvInterestReducer";
import privacyReducer from './PrivacyReducer';
import CourseReducer from './CourseReducer';
import StudentReducer from './StudentReducer';
import LeadsReducer from './LeadsReducer';
import ReviewCourseReducer from './ReviewCourseReducer';
import BannerReducer from './BannerReducer';
import AboutUsReducer from './AboutUsReducer';
import TermsConditionsReducer from './TermsConditionsReducer';
import RefundPolicyReducer from './RefundPolicyReducer';
import InstructorLeadsReducer from './InstructorLeadsReducer';
import SupportQueryReducer from './SupportQueryReducer';
import AstroReducer from './AstroReducer';
import LanguageReducer from './LanguageReducer';
import UserManagementReducer from './UserManagementReducer';


export default combineReducers({
    Mainreducer,
    ProductReducer,
    AboutUsReducer,
    ReviewCourseReducer,
    CategoryReducer,
    UserManagementReducer,
    LanguageReducer,
    RefundPolicyReducer,
    SubCategoryReducer,
    AstroReducer,
    AttributeReducer,
    TagReducer,
    CouponReducer,
    InstructorLeadsReducer,
    SupportQueryReducer,
    BrandReducer,
    InterestsReducer,
    ProfileReducer,
    ServiceReducer,
    CommissionReducer,
    FAQReducer,
    UsersReducer,
    BannerReducer,
    TermsConditionsReducer,
    CsvInterestReducer,
    privacy: privacyReducer,
    CourseReducer,
    StudentReducer,
    LeadsReducer
})